//
// FONTS //
//
//
$font-heading: 'Inter','NotoSans','NotoSansJP','NotoSansKR',sans-serif
$font-display: serif
$font-paragraph: 'Inter', 'NotoSans','NotoSansJP','NotoSansKR',sans-serif
$font-paragraph-bold: 'Inter', 'NotoSans','NotoSansJP','NotoSansKR',sans-serif
$font-paragraph-extra-bold: 'Inter', 'NotoSans','NotoSansJP','NotoSansKR',sans-serif
//
//
$font-regular-weight: 400 !default
$font-medium-weight: 500 !default
$font-bold-weight: 600 !default
$font-extra-bold-weight: 700 !default
//
$font-heading-weight: $font-medium-weight
//
// TYPOGRAPHY
//
$type-size-xxl: 72 
$type-size-xl: 52 
$type-size-l: 32 
$type-size-m: 24 
$type-size-s: 20
$type-size-xs: 17
//
$type-size-xxl-mobile: 48 
$type-size-xl-mobile: 36 
$type-size-l-mobile: 24 
$type-size-m-mobile: 20
$type-size-s-mobile: 18
$type-size-xs-mobile: 15
//
$heading-line-height: 110%
$body-line-height: 150%
//
$heading-letter-spacing: -0.05
$body-letter-spacing: -0.02
$all-caps-letter-spacing: 0.1
//
//
//
%heading
  font-family: $font-heading
  font-weight: $font-heading-weight

%paragraph
  font-family: $font-paragraph
  font-weight: $font-regular-weight
//
//
//
%regular
  font-weight: $font-regular-weight

%medium
  font-weight: $font-medium-weight

%bold
  font-weight: $font-bold-weight

%extra-bold
  font-weight: $font-extra-bold-weight

%body-bold
  font-family: $font-paragraph-extra-bold
  @extend %bold
//
//
//
//
@mixin fontSize($size, $line, $spacing)
  font-size: $size / 10 + rem
  line-height: $line
  letter-spacing: $size * $spacing + px
//
//
//
//
%heading-xl
  @extend %heading
  font-family: $font-display
  @include fontSize($type-size-xxl-mobile, $heading-line-height, $heading-letter-spacing)
  @include min($screen-m)
    @include fontSize($type-size-xl, $heading-line-height, $heading-letter-spacing)
  @include min($screen-l)
    @include fontSize($type-size-xxl, 100%, $heading-letter-spacing)

%heading-l
  @extend %heading
  font-family: $font-display
  @include fontSize(calc($type-size-l-mobile * 1.4), $heading-line-height, $heading-letter-spacing)
  @include min($screen-l)
    @include fontSize(calc($type-size-l * 1.4), $heading-line-height, $heading-letter-spacing)

%heading-m
  @extend %heading
  font-family: $font-display
  @include fontSize(calc($type-size-m-mobile * 1.3), $heading-line-height, $heading-letter-spacing)
  @include min($screen-l)
    @include fontSize(calc($type-size-m * 1.4), $heading-line-height, $heading-letter-spacing)
    
%heading-s
  @extend %heading
  @extend %bold
  @include fontSize($type-size-s-mobile, $heading-line-height, $heading-letter-spacing)
  @include min($screen-l)
    @include fontSize($type-size-s, $heading-line-height, $heading-letter-spacing)
    
%heading-xs
  @extend %heading
  @include fontSize($type-size-xs-mobile, $heading-line-height, $heading-letter-spacing)
  @include min($screen-l)
    @include fontSize($type-size-xs, $heading-line-height, $heading-letter-spacing)

%heading-block
  @extend %heading
  @extend %bold
  text-transform: uppercase
  @include fontSize($type-size-m-mobile, $heading-line-height, $heading-letter-spacing)
  @include min($screen-l)
    @include fontSize($type-size-m, $heading-line-height, $heading-letter-spacing)
//
//
//
%body-xl
  @extend %regular
  @extend %paragraph
  @include fontSize($type-size-xl-mobile, $body-line-height, $body-letter-spacing)
  @include min($screen-l)
    @include fontSize($type-size-xl, $body-line-height, $body-letter-spacing)
    
%body-l
  @extend %regular
  @extend %paragraph
  @include fontSize($type-size-l-mobile, $body-line-height, $body-letter-spacing)
  @include min($screen-l)
    @include fontSize($type-size-l, $body-line-height, $body-letter-spacing)

%body-m
  @extend %regular
  @extend %paragraph
  @include fontSize($type-size-m-mobile, $body-line-height, $body-letter-spacing)
  @include min($screen-l)
    @include fontSize($type-size-m, $body-line-height, $body-letter-spacing)

%body-s
  @extend %regular
  @extend %paragraph
  @include fontSize($type-size-s-mobile, $body-line-height, $body-letter-spacing)
  @include min($screen-l)
    @include fontSize($type-size-s, $body-line-height, $body-letter-spacing)

%body-xs
  @extend %regular
  @extend %paragraph
  @include fontSize($type-size-xs-mobile, $body-line-height, $body-letter-spacing)
  @include min($screen-l)
    @include fontSize($type-size-xs, $body-line-height, $body-letter-spacing)
//
//
//
%button-l
  @extend %body-m
  @extend %bold
%button-m
  @extend %body-s
  @extend %bold
%button-s
  @extend %body-s
  @extend %bold
//
//
//
%typography-hierarchy
  p
    @extend %body-s
    @extend %paragraph
    
  a
    text-decoration: underline

  p, ul
    + h2, + h3
      margin-top: units(4)
    + h4, + h5
      margin-top: units(3)

  blockquote
    margin-top: units(3)
    padding: units(3) 0 0 0

  .body-0
    @extend %body-l
  .body-1
    @extend %body-m
  .body-2
    @extend %body-s
  .button-text
    @extend %button-l
    
  strong, b
    @extend %bold


  h1, .heading-l
    @extend %heading-xl
    margin-bottom: units(4)
  h2, .heading-m
    @extend %heading-l
    margin-bottom: units(3)
  h3, .heading-s
    @extend %heading-m
    margin-bottom: units(1)
  h4
    @extend %body-l
    margin-bottom: units(1)
  h5
    @extend %heading-s
  h6
    @extend %heading-m
    margin-bottom: units(1)
  ul
    @extend %standard-list
  hr
    height: 1
    border: none
    background: $grey-300
    margin: units(4) 0 units(3) 0
