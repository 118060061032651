@import 'styles/site'

.App
  //position: relative
  width: 100vw
  padding: 0 0 0 0
  @include min($screen-m)
    padding-top: 1px//$border-m
  @include min($screen-l)
    padding-top: 1px//$border-l
    
  .content
    position: relative
    width: 100%
    overflow-x: hidden
