@import 'styles/site.sass'

.Main
  background: $white
  padding: 0 0 $space-l 0
  margin-top: $space-l
  
  @extend %site-links
  border-top: 1px solid rgba(black, 0.2)

.content
  padding: $space-m 0
