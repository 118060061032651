@import 'styles/site.sass'

.Main
  // border-radius: 0//20px 20px $radius-section-s $radius-section-s
  // @include min($screen-m)
  //   border-radius: 20px 20px $radius-section-m $radius-section-m
  // @include min($screen-l)
  //   border-radius: 20px 20px $radius-section-l $radius-section-l

  position: relative
  overflow: hidden
  // backdrop-filter: blur(100px)
  // background: rgba(255, 255, 255, 0.1)
  color: $black
  padding: $space-l 0 $space-m 0
  border-bottom: 1px solid rgba(black, 0.2)
  
  @include min($screen-l)
    padding-top: calc(#{$space-l} + #{$nav-bar-height})
    padding-bottom: $space-l

  header
    position: relative
    margin: 0 auto
    @include min($screen-m)
      display: flex
      justify-content: space-between

    > div
      flex: 1
    
    .content
      flex: 1
      display: flex
      flex-direction: column
      align-items: flex-end
      justify-content: flex-end
      
      @include max($screen-l)
        margin-top: units(1)

    h2
      @extend %body-l
      max-width: units(70)
    
.links
  padding: $space-s 0
  @include min($screen-l)
    padding: $space-m 0
  border-top: 1px solid currentColor
  // border-bottom: $border-width solid currentColor
  
  > h5
    @extend %heading-m
    margin-bottom: units(5)
    
  > div
    display: grid
    grid-gap: $space-l

    @include min($screen-l)
      grid-template-columns: 1fr 1fr 1fr
    
